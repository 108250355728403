<template>
  <div class="page">
    <!--  导航  -->
    <nav-bar PitchOn="文章"></nav-bar>
<!--    主体-->
    <div class="main-info">
      <div class="info-left">
        <div class="guide-info">
          <el-breadcrumb :separator-icon="ArrowRight">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>文章</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="article-list">
          <transition-group
              name="more"
              v-bind:css="false"
              v-on:before-enter="beforeEnter"
              v-on:enter="enter">
            <div class="list" v-for="(item,index) in articleList"
                 :data-index="index"
                 @click="onArticle(item)"
                 :key="index">
              <div class="picture">
                <el-image class="el-img" :src="item.img"></el-image>
                <div class="tag">
                  <span v-if="item.is_top === 1">置顶</span>
                </div>
              </div>
              <div class="item">
                <div class="title">
                  <span>{{item.title}}</span>
                </div>
                <div class="desc">
                  <span>{{item.describe}}</span>
                </div>
                <div class="date-view">
                  <div class="view">
                    <span class="iconfont icon-shijian"></span>
                    <span>{{parseTime(item.create_time,'{y}-{m}-{d}')}}</span>
                  </div>
                  <div class="view">
                    <span class="iconfont icon-liulan"></span>
                    <span>{{item.page_view}}人观看</span>
                  </div>
                  <div class="view">
                    <span class="iconfont icon-pinglun"></span>
                    <span>{{item.comment_num}}人吐槽</span>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
        <el-empty description="无数据" v-show="articleList.length === 0"></el-empty>
        <div class="pagination">
          <el-pagination background layout="prev, pager, next"
                         @current-change="changePage"
                         :page-size="20"
                         :total="total">
          </el-pagination>
        </div>
      </div>
      <div class="info-right">
        <page-right></page-right>
      </div>
    </div>
<!--    <vue-editor v-model="value"></vue-editor>-->
  </div>
</template>

<script>
import NavBar from "../../components/NavBar/NavBar";
import PageRight from "../../components/PageRight/PageRight";
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {ArticleList} from "../../api/api";
import {useRouter} from "vue-router";

export default {
  name: "article",
  components: {PageRight, NavBar},
  setup(){
    let { proxy } = getCurrentInstance();
    const router = useRouter();
    const data = reactive({
      articleList: [],
      parseTime: '',
      value: '',
      // 页码
      page: 1,
      total: 0,
      /**
       * 获取数据
       */
      getData(){
        ArticleList({
          page: data.page
        }).then(res =>{
          data.articleList = res.data.result;
          data.total = res.data.total;
        })
      },
      /**
       * 页码改变
       * @param page
       */
      changePage(page){
        data.page = page;
        data.getData();
      },
      /**
       * 文章点击
       * @param item
       */
      onArticle(item){
        router.push({path: '/ArticleDetails',query: {article_id: item.article_id}})
      },
      beforeEnter (el) {
        el.style.opacity = 0
      },
      enter (el, done) {
        let delay = el.dataset.index * 100
        setTimeout(()=>{
          el.style.transition = 'opacity 0.4s '
          el.style.opacity = 1
          el.style.animation = 'one-in 0.4s infinite'
          el.style['animation-iteration-count'] = 1
          done()
        }, delay)
      }
    })
    onMounted(() => {
      data.parseTime = proxy.util.parseTime;
      data.getData();
    })
    return {
      ...toRefs(data),
    }
  }
}
</script>

<style scoped lang="scss">
  @import "article.scss";
</style>
